.Home {
  width: 100%;
}

@media all and (min-width: 600px) {
  .Home {
    width: 1200px;
  }
}

.Home .card {
  margin-bottom: 15px;
}
