@media all and (min-width: 480px) {
  .App .body {
    width: 100%;
    height: 100%;
    display: flex;
    padding: 60px 0;
    justify-content: center;
    align-items: center;
  }
}

.logo-icon {
  max-width: 300px;
}

.logo-name {
  max-width: 300px;
}

.bg-orange {
  background-color: #81caf5;
}

.navbar {
  padding-left: 15vw;
  padding-right: 15vw;
}
